.title h1 {
    margin-left: 10%;
    margin-top: 0;
    margin-right: 10%;
}

.title h2 {
    margin-left: 10%;
}

.title p {
    font-family: "Nunito";
    font-size: 20px;
    margin-left: 10%;
    margin-top: 25%;
}

.hero {
    height: 100vh;
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.hero img {
    object-fit: contain;
    width: 90%;
    margin: auto;
    border-radius: 10px;
    margin-top: 32.75%;
    max-height: 500px;
}

.gridproject {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 70%;
    margin: auto;
    gap: 50px;
    margin-bottom: 50px;
}


.item {
    border-radius: 30px;
    background: rgba(255, 255, 255, 0.22);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5.2px);
    -webkit-backdrop-filter: blur(5.2px);
    border: 1px solid rgba(255, 255, 255, 0.44);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.item h4 {
    margin-top: 20px;
}

.item p {
    width: 90%;
    font-family: "Nunito";
    font-size: 16px;
    margin-bottom: 20px;
}

.gridskills {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
    row-gap: 0px;
    margin: auto;
    width: 80%;
    grid-template-rows: 120px 120px;
    margin-top: 50px;
}

.gridskills div {
    border-radius: 30px;
    background: rgba(255, 255, 255, 0.22);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5.2px);
    -webkit-backdrop-filter: blur(5.2px);
    border: 1px solid rgba(255, 255, 255, 0.44);
}

.item1skills {
    grid-row: 1 / 4;
}

.item1skills ul {
    list-style: none;
    font-family: "Nunito";
    font-size: 20px;
}

.item1skills li {
    margin-bottom: 10px;
}

.gridskills p {
    list-style: none;
    font-family: "Nunito";
    font-size: 20px;
    text-align: center;
    margin-top: 5px;
}

.smaller {
    height: 100px;
}

.last {
    grid-column: 2/3;
}

.link-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 90%;
    justify-items: center;
    margin-top: 20px;
}

.link-container a {
    text-align: center;
    width: 80%;
    border-radius: 30px;
    background: rgba(255, 255, 255, 0.22);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5.2px);
    -webkit-backdrop-filter: blur(5.2px);
    border: 1px solid rgba(255, 255, 255, 0.44);
    margin: 0;
    color: black;
    border-radius: 50px;
    padding: 15px;
    font-family: "Nunito";
    font-size: 20px;
    text-decoration: none;
}

.link-container p {
    text-align: center;
    width: 80%;
    border-radius: 30px;
    background: rgba(255, 255, 255, 0.22);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5.2px);
    -webkit-backdrop-filter: blur(5.2px);
    border: 1px solid rgba(255, 255, 255, 0.44);
    margin: 0;
    color: black;
    border-radius: 50px;
    padding: 15px;
    font-family: "Nunito";
    font-size: 20px;
    text-decoration: none;
}
