.contactbackground {
    position: fixed;
    z-index: 2;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center; /* center horizontally */
    align-items: center;
}


.contact {
    background-color: rgba(255, 255, 255, 0.44);
    border-radius: 10px;
    width: 30%;
    height: 50%;
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5.2px);
    -webkit-backdrop-filter: blur(5.2px);
    border: 1px solid rgba(255, 255, 255, 0.44);
}


.contact h4 {
    margin-top: 20px;
    font-size: 30px;
}

label {
    font-family: "Nunito";
    margin-left: 15%;
    font-size: 20px;
}

.contact input {
    margin-top: 5px;
    width: 70%;
    margin-left: 15%;
    height: 30px;
    margin-bottom: 10px;
    padding-left: 10px;
    font-family: "Nunito";
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5.2px);
    -webkit-backdrop-filter: blur(5.2px);
    border: 1px solid rgba(255, 255, 255, 0.44);
    background-color: rgba(255, 255, 255, 0.44);
    border-radius: 10px;
}

select {
    margin-top: 5px;
    width: 70%;
    margin-left: 15%;
    height: 30px;
    margin-bottom: 10px;
    padding-left: 10px;
    font-family: "Nunito";
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5.2px);
    -webkit-backdrop-filter: blur(5.2px);
    border: 1px solid rgba(255, 255, 255, 0.44);
    background-color: rgba(255, 255, 255, 0.44);
    border-radius: 10px;
}

textarea {
    margin-top: 5px;
    width: 70%;
    margin-left: 15%;
    height: 80px;
    margin-bottom: 15px;
    padding-left: 10px;
    font-family: "Nunito";
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5.2px);
    -webkit-backdrop-filter: blur(5.2px);
    border: 1px solid rgba(255, 255, 255, 0.44);
    background-color: rgba(255, 255, 255, 0.44);
    border-radius: 10px;
    resize: none;
}

button {
    margin-top: 5px;
    width: 70%;
    margin-left: 15%;
    height: 40px;
    margin-bottom: 15px;
    padding-left: 10px;
    font-family: "Nunito";
    font-size: 20px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5.2px);
    -webkit-backdrop-filter: blur(5.2px);
    border: 1px solid rgba(255, 255, 255, 0.44);
    background-color: rgba(255, 255, 255, 0.44);
    border-radius: 10px;
    cursor: pointer;
}

.x {
   position: absolute; 
   top: 6%;
   right: 6%;
   font-size: 30px;
   cursor: pointer;
}



