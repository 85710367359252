.sudoku-grid {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 25%;
  margin-right: 10%;
}

.sudoku-row {
  display: flex;
}

input {
  width: 40px;
  height: 40px;
  text-align: center;
  margin: 2.5px;
  border-radius: 5px;
  border: 1px solid black;
  font-family: "Nunito";
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
