/* General Tags */
* {
    scroll-behavior: smooth;
}


h1 {
    font-family: "Nunito";
    font-size: 70px;
}

h3 {
    text-align: center;
    font-size: 45px;
    font-family: "Nunito";
    padding-top: 100px;
}

h4 {
    text-align: center;
    font-family: "Nunito";
    font-size: 25px;
    margin-top: 10px;
    margin-bottom: 10px;
}

h5 {
    margin-left: 15%;
    font-family: "Nunito";
    font-size: 20px;
    margin-bottom: 0;
}

/* Hero Section */

.herotext {
    height: 100vh;
}

.herotext h1 {
    text-align: center;
    padding-top: 32vh;
}

.herotext h2 {
    text-align: center;
    margin-top: 5%;
    font-family: "Open Sans";
}

/* Part 2 */

.part2 {
    height: 70vh;

}


.skillscontainer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 50px;
    margin: auto;
    margin-top: 5%;
    width: 80%;
}

.skill {
    height: 400px;
    border-radius: 30px;
    background: rgba(255, 255, 255, 0.22);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5.2px);
    -webkit-backdrop-filter: blur(5.2px);
    border: 1px solid rgba(255, 255, 255, 0.44);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.skill p {
    text-align: center;
    font-family: "Nunito";
    font-size: 18px;
    padding: 0px 40px 0px 40px;
}

.icon1 {
    font-size: 40px;
    color: lightblue;
}

.icon2 {
    font-size: 40px;
    color: darkblue;
}

.icon3 {
    font-size: 40px;
    color: darkblue;
}

/* Projects Section */

.grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin: auto;
    width: 70%;
    gap: 50px;

}

.biggerdiv {
    height: 755px;
    grid-column: span 2;
    border-radius: 30px;
    background: rgba(255, 255, 255, 0.22);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5.2px);
    -webkit-backdrop-filter: blur(5.2px);
    border: 1px solid rgba(255, 255, 255, 0.44);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.smallerdiv {
    height: 350px;
    border-radius: 30px;
    background: rgba(255, 255, 255, 0.22);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5.2px);
    -webkit-backdrop-filter: blur(5.2px);
    border: 1px solid rgba(255, 255, 255, 0.44);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.item1 {
    grid-row: 1 / 3;
}

.item5 {
    grid-row: 3 / 5;
    grid-column: 2/4;
}

.biggerdiv img {
    position: absolute;
    top: 0;
    border-radius: 10px;
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.smallerdiv img {
    position: absolute;
    max-width: 100%;
    top: 0;
    border-radius: 10px;
    min-width: 100%;
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.filterdiv {
    display: inline-flex;
    margin-left: 15%;
    margin-bottom: 10px;
}

.filterdiv p {
    font-family: "Nunito";
    margin-right: 20px;
    font-size: 20px;
    cursor: pointer;
}

.bold {
    font-weight: 700;
}

.filterdiv p:hover {
    font-weight: 700; 
}

.info {
    background-color: white;
    position: absolute;
    bottom: 0;
    height: 125px;
    width: 100%;
    margin: 0%;
    border-radius: 0px 0px 10px 10px;
}

.info p {
    margin-left: 15%;
    font-family: "Nunito";
    margin-top: none;
}


.educationandexp {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: auto;
    width: 70%;
    gap: 30px;
}

.expcont {
    height: auto;
    border-radius: 30px;
    background: rgba(255, 255, 255, 0.22);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5.2px);
    -webkit-backdrop-filter: blur(5.2px);
    border: 1px solid rgba(255, 255, 255, 0.44);
    margin-bottom: 30px;
}




#bg-wrap {
    position: fixed;
    top:0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: -10;
}

.hidden {
    display: none;
}

.expconth5 {
    margin-top: 25px;
}


.expconth6 {
    margin-top: 10px;
    margin-left: 15%;
    font-size: 15px;
    font-weight: 300;
    margin-bottom: 0px;
}

.expconth62 {
    font-size: 15px;
    font-weight: 300;
    margin-top: 10px;
    margin-left: 15%;
}

.activeExpCont {
    height: auto;
    border-radius: 30px;
    background: rgba(255, 255, 255, 0.22);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5.2px);
    -webkit-backdrop-filter: blur(5.2px);
    border: 1px solid rgba(255, 255, 255, 0.44);
    margin-bottom: 30px;
}

.listt {
    list-style: none;
    font-family: "Nunito";
    padding: 0;
    width: 70%;
    margin: auto;
    margin-bottom: 50px;
}

/* Media Queries */

@media (max-width: 1130px) {
    .skillscontainer {
        grid-template-columns: 1fr;
    }
    .part2 {
        height: 120vh;
    }
    .grid {
        grid-template-columns: 1fr;
    }
    .biggerdiv {
        grid-column: span 1;
    }
    .educationandexp {
        grid-template-columns: 1fr;
    }
}








