.navbarlist {
    position: fixed;
    top: 0;
    margin-left: 65%;
    margin-top: 2%;

    display: flex;
    list-style: none;
}

.navbarlist a {
    margin-right: 30px;
    font-size: 25px;
    color: black;
    text-decoration: none;
    font-family: "Nunito";
    cursor: pointer;
}

.navbarlist a {
    margin-right: 30px;
    font-size: 25px;
    color: black;
    text-decoration: none;
    font-family: "Nunito";
    cursor: pointer;
    margin-top: 0;
}

.navbarlist a:hover {
    font-weight: 700;
}

.navbarlist p:hover {
    font-weight: 700;
}

